'use client';

import { TestType } from '@/types';
import { useEffect, useState } from 'react';
import { storageService } from '@/services/storageService';

interface TestConfigModalProps {
    type: TestType;
    isOpen: boolean;
    onClose: () => void;
}

export default function TestConfigModal({ type, isOpen, onClose }: TestConfigModalProps) {
    const [questionCount, setQuestionCount] = useState(10);
    const [hideProgress, setHideProgress] = useState(false);
    const [spatialSymbol, setSpatialSymbol] = useState('R');
    const [minScope, setMinScope] = useState(0);
    const [maxScope, setMaxScope] = useState(28);

    useEffect(() => {
        if (isOpen) {
            const settings = storageService.getSettings();
            setQuestionCount(settings.questionCounts?.[type] || 10);
            setHideProgress(settings.hideProgress?.[type] || false);
            
            if (type === 'spatial') {
                setSpatialSymbol(settings.spatialSymbol || 'R');
            }
            
            if (type === 'numerical') {
                const min = settings.numericalScope?.min ?? 0;
                const max = settings.numericalScope?.max ?? 28;
                
                // Ensure the constraints are met when loading settings
                if (max - min < 20) {
                    setMinScope(min);
                    setMaxScope(min + 20);
                } else {
                    setMinScope(min);
                    setMaxScope(max);
                }
            }
        }
    }, [isOpen, type]);

    // Function to handle min scope changes
    const handleMinScopeChange = (value: number) => {
        const newMin = Math.max(0, value);
        setMinScope(newMin);
        
        // Adjust max if needed to maintain constraints
        if (maxScope - newMin < 20) {
            setMaxScope(newMin + 20);
        }
    };

    // Function to handle max scope changes
    const handleMaxScopeChange = (value: number) => {
        const newMax = Math.max(minScope + 20, value);
        setMaxScope(newMax);
    };

    const handleSave = () => {
        const settings = storageService.getSettings();
        const updatedSettings = {
            ...settings,
            questionCounts: {
                ...settings.questionCounts,
                [type]: questionCount
            },
            hideProgress: {
                ...settings.hideProgress,
                [type]: hideProgress
            }
        };
        
        if (type === 'spatial') {
            updatedSettings.spatialSymbol = spatialSymbol || 'R';
        }
        
        if (type === 'numerical') {
            updatedSettings.numericalScope = {
                min: minScope,
                max: maxScope
            };
        }
        
        storageService.updateSettings(updatedSettings);
        onClose();
    };

    if (!isOpen) return null;

    const getTestName = (type: TestType): string => {
        const names: Record<TestType, string> = {
            reasoning: 'Reasoning Test',
            perceptual: 'Perceptual Speed Test',
            numerical: 'Number Speed & Accuracy Test',
            verbal: 'Word Meaning Test',
            spatial: 'Spatial Visualization Test'
        };
        return names[type];
    };

    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
                <div className="p-6 space-y-6">
                    <h2 className="text-2xl font-semibold text-slate-800">
                        Configure {getTestName(type)}
                    </h2>

                    <div className="space-y-4">
                        <label className="block">
                            <span className="text-slate-600">Number of Questions</span>
                            <input
                                type="number"
                                min="1"
                                max="50"
                                value={questionCount}
                                onChange={(e) => setQuestionCount(Math.max(1, Math.min(50, parseInt(e.target.value) || 10)))}
                                className="mt-1 block w-full px-3 py-2 border border-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            />
                        </label>
                        <p className="text-sm text-slate-500">
                            Choose between 1 and 50 questions. Default is 10.
                        </p>
                        
                        {type === 'spatial' && (
                            <>
                                <label className="block mt-4">
                                    <span className="text-slate-600">Symbol Character</span>
                                    <input
                                        type="text"
                                        maxLength={1}
                                        value={spatialSymbol}
                                        onChange={(e) => setSpatialSymbol(e.target.value)}
                                        className="mt-1 block w-full px-3 py-2 border border-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </label>
                                <p className="text-sm text-slate-500">
                                    Choose any single character to use in the spatial visualization test. Default is 'R'.
                                </p>
                            </>
                        )}
                        
                        {type === 'numerical' && (
                            <>
                                <div className="mt-4 grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block">
                                            <span className="text-slate-600">Minimum Number</span>
                                            <input
                                                type="number"
                                                min="0"
                                                max="80"
                                                value={minScope}
                                                onChange={(e) => handleMinScopeChange(parseInt(e.target.value) || 0)}
                                                className="mt-1 block w-full px-3 py-2 border border-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <label className="block">
                                            <span className="text-slate-600">Maximum Number</span>
                                            <input
                                                type="number"
                                                min={minScope + 20}
                                                value={maxScope}
                                                onChange={(e) => handleMaxScopeChange(parseInt(e.target.value) || 28)}
                                                className="mt-1 block w-full px-3 py-2 border border-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                            />
                                        </label>
                                    </div>
                                </div>
                                <p className="text-sm text-slate-500">
                                    Set the range of numbers used in the test. The maximum must be at least 20 more than the minimum.
                                </p>
                            </>
                        )}
                        
                        <div className="mt-4">
                            <label className="flex items-center space-x-2 cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={hideProgress}
                                    onChange={(e) => setHideProgress(e.target.checked)}
                                    className="rounded border-slate-300 text-blue-500 focus:ring-blue-500"
                                />
                                <span className="text-slate-600">Hide progress bar and timer</span>
                            </label>
                            <p className="text-sm text-slate-500 mt-1">
                                This can help reduce test anxiety and create a more focused environment.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="px-6 py-4 bg-slate-50 rounded-b-lg flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 border-2 border-slate-300 rounded-lg text-slate-600 hover:border-slate-400 transition-colors"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
} 