import { TestResult, UserSettings, TestType } from '@/types';

const STORAGE_KEY = 'thomas_gia_practice';

interface StorageData {
    resultsByType: {
        [key in TestType]: TestResult[];
    };
    settings: UserSettings;
}

const defaultData: StorageData = {
    resultsByType: {
        reasoning: [],
        perceptual: [],
        numerical: [],
        verbal: [],
        spatial: []
    },
    settings: {
        timerEnabled: true,
        showAids: true,
        questionCounts: {
            reasoning: 10,
            perceptual: 10,
            numerical: 10,
            verbal: 10,
            spatial: 10
        },
        hideProgress: {
            reasoning: false,
            perceptual: false,
            numerical: false,
            verbal: false,
            spatial: false
        },
        spatialSymbol: 'R',
        numericalScope: {
            min: 0,
            max: 28
        }
    }
};

export const storageService = {
    saveResults(result: TestResult) {
        const data = this.getData();
        data.resultsByType[result.type].push(result);
        this.setData(data);
    },

    getResults(): TestResult[] {
        const data = this.getData();
        return Object.values(data.resultsByType).flat() as TestResult[];
    },

    getResultsByType(type: TestType): TestResult[] {
        const data = this.getData();
        return data.resultsByType[type] || [];
    },

    getSettings(): UserSettings {
        return this.getData().settings;
    },

    updateSettings(settings: Partial<UserSettings>) {
        const data = this.getData();
        data.settings = { ...data.settings, ...settings };
        this.setData(data);
    },

    getData(): StorageData {
        if (typeof window === 'undefined') return defaultData;
        
        const stored = localStorage.getItem(STORAGE_KEY);
        if (!stored) return defaultData;
        const parsedData = JSON.parse(stored);
        
        return parsedData;
    },

    setData(data: StorageData) {
        if (typeof window === 'undefined') return;
        localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    },

    clearData() {
        if (typeof window === 'undefined') return;
        localStorage.removeItem(STORAGE_KEY);
    }
}; 