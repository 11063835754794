'use client';

export default function Header() {
    return (
        <header className="bg-gray-50 py-12 mb-8">
            <div className="container mx-auto px-4">
                <blockquote className="max-w-3xl mx-auto text-center">
                    <p className="text-xl text-gray-700 italic mb-4">
                        "It's important to learn that you can learn anything you want, and that you can get better quickly. This feels like an unlikely miracle the first few times it happens, but eventually you learn to trust that you can do it."
                    </p>
                    <footer className="text-sm text-gray-500">
                        <a 
                            href="https://blog.samaltman.com/productivity" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="hover:text-blue-500 underline"
                        >
                            Sam Altman on Productivity
                        </a>
                    </footer>
                </blockquote>
            </div>
        </header>
    );
} 