'use client';

import Link from 'next/link';
import Header from '@/components/common/Header';
import { TestType } from '@/types';
import { useState } from 'react';
import TestConfigModal from '@/components/settings/TestConfigModal';
import AdPlacement from '@/components/Ads/AdPlacement';

const testTypes: { type: TestType; label: string; description: string }[] = [
    {
        type: 'reasoning',
        label: 'Reasoning',
        description: 'Logic-based questions with comparative statements'
    },
    {
        type: 'perceptual',
        label: 'Perceptual Speed',
        description: 'Letter pair matching exercises'
    },
    {
        type: 'numerical',
        label: 'Number Speed & Accuracy',
        description: 'Quick mathematical comparisons'
    },
    {
        type: 'verbal',
        label: 'Word Meaning',
        description: 'Identify relationships between words'
    },
    {
        type: 'spatial',
        label: 'Spatial Visualization',
        description: 'Symbol rotation and mirror exercises'
    }
];

export default function Home() {
    const [configType, setConfigType] = useState<TestType | null>(null);

    return (
        <>
            <Header />
            <main className="container mx-auto px-4 py-8">
                <div className="flex flex-col lg:flex-row gap-8">
                    <div className="flex-grow">
                        <h1 className="text-4xl font-bold mb-4">Free Thomas GIA Practice Tests</h1>
                        <p className="text-lg text-gray-600 mb-8">
                            Comprehensive free test preparation suite for the Thomas General Intelligence Assessment (GIA). 
                            Practice with our free test pack designed to help you succeed in general aptitude tests.
                        </p>
                        {/* Informative box about data privacy */}
                        <div className="bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 rounded relative mb-6" role="alert">
                            <strong className="font-bold">Upcoming Features & Fixes: </strong><br/>
                            <ul>
                                <li>
                                    * <del>Fix "purple orange apple" type of questions in word meaning</del> ✅
                                </li>
                                <li>
                                    * <del>Fix two numbers are very close to each other in numerical test</del> ✅
                                </li>
                                <li>
                                    * <del>Option to hide progress bar and timer</del> ✅
                                </li>
                                <li>
                                    * <del>Allow to select letter for spatial test</del> ✅
                                </li>
                            </ul>
                            <br/>
                            <strong className="font-bold">You can now find all custom setting by clicking the gear icon. Thank you for your feedback!</strong>
                            {/* <br /><strong className="font-bold">Latest Update (Jan 2025): </strong>
                            <p className="block sm:inline">Fix a bug in numerical test where the exact median number is used as the middle number. Thanks for the feedback!</p> */}
                        </div>
                        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-6" role="alert">
                            <strong className="font-bold">Note: </strong>
                            <p className="block sm:inline">This site does not collect any personal user information. All test data is stored locally in your browser. Enjoy! 🎉</p>
                            <p className="mt-2 flex items-center">If you think this site is helpful, please consider 
                                <a href="https://www.buymeacoffee.com/dandrake" className="inline-block ml-2">
                                    <img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=dandrake&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff" />
                                </a>
                            </p>
                            <button
                                type="button"
                                className="absolute bottom-1 right-1 px-2 py-1 text-green-700 hover:text-green-900 focus:outline-none"
                                onClick={() => {
                                    const element = document.querySelector('.bg-green-100') as HTMLElement;
                                    if (element) {
                                        element.style.display = 'none';
                                    }
                                }}
                            >
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"/></svg>
                            </button>
                        </div>

                        <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-6" role="alert">
                            <strong className="font-bold">Check out my new macOS app -&gt; </strong>
                            <a href="https://nichepark.com/pastemd" className="block sm:inline font-semibold hover:underline animate-pulse">PasteMD</a>
                            <p className="block sm:inline"> Copy and paste any text on your Desktop without creating a file first</p>
                        </div>

                        <h2 className="text-2xl font-semibold mb-6">Available Practice Tests</h2>
                        <div className="grid md:grid-cols-2 gap-6">
                            {testTypes.map(({ type, label, description }) => (
                                <article
                                    key={type}
                                    className="group relative"
                                >
                                    <Link
                                        href={`/practice/${type}/intro`}
                                        className="block p-6 border rounded-lg hover:border-blue-500 transition-colors"
                                    >
                                        <h3 className="text-xl font-semibold mb-2">{label}</h3>
                                        <p className="text-gray-600">{description}</p>
                                        <p className="mt-4 text-blue-600 group-hover:text-blue-700">
                                            Start free practice test →
                                        </p>
                                    </Link>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setConfigType(type);
                                        }}
                                        className="absolute top-2 right-2 p-2 rounded-full bg-white border border-slate-200 shadow-sm opacity-0 group-hover:opacity-100 transition-opacity hover:border-slate-300"
                                        title="Configure test"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                    </button>
                                </article>
                            ))}
                        </div>

                        <section className="mt-12 bg-gray-50 p-8 rounded-lg">
                            <h2 className="text-2xl font-semibold mb-4">Why Practice with Our Free Test Suite?</h2>
                            <ul className="space-y-4">
                                <li className="flex items-start">
                                    <svg className="w-6 h-6 text-green-500 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    <p>Comprehensive general intelligence test preparation</p>
                                </li>
                                <li className="flex items-start">
                                    <svg className="w-6 h-6 text-green-500 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    <p>Practice all aspects of the Thomas GIA test format</p>
                                </li>
                                <li className="flex items-start">
                                    <svg className="w-6 h-6 text-green-500 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    <p>Free test pack with detailed performance tracking</p>
                                </li>
                                <li className="flex items-start">
                                    <svg className="w-6 h-6 text-green-500 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    <p>Unique visual learning aids to help you improve your performance</p>
                                </li>
                            </ul>
                        </section>
                    </div>
                    
                    {/* Sidebar ad */}
                    <div className="lg:w-72">
                        {/* <AdPlacement position="sidebar" className="sticky top-4" /> */}
                    </div>
                </div>

                {configType && (
                    <TestConfigModal
                        type={configType}
                        isOpen={true}
                        onClose={() => setConfigType(null)}
                    />
                )}
            </main>
        </>
    );
} 